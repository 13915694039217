import { deleteCookie, getCookie, setCookie } from '~persistence/cookies';
const TEST_STORAGE_KEY = 'unbounded_storage_test';
function canUseCookies() {
    const r = Math.random().toString();
    setCookie(TEST_STORAGE_KEY, r);
    if (getCookie(TEST_STORAGE_KEY) === r) {
        deleteCookie(TEST_STORAGE_KEY);
        return true;
    }
    return false;
}
// On Chrome, we can use localStorage in an iframe and it will be propagated to the real website.
// On Safari, we can use cookies in an iframe (if it was granted access) and it will be propagated to the real website.
// The other way around it will break and data is lost. So we need to decide correctly.
export function setStorage(key, value) {
    if (canUseCookies()) {
        setCookie(key, value);
    }
    else {
        window.localStorage[key] = value;
    }
}
export function getStorage(key) {
    if (canUseCookies()) {
        return getCookie(key);
    }
    return window.localStorage[key];
}
export function deleteStorage(key) {
    if (canUseCookies()) {
        deleteCookie(key);
    }
    else {
        delete window.localStorage[key];
    }
}
