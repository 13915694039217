// @ts-ignore TypeScript does not natively allow "*.ts" syntax and fails during build.
// eslint-disable-next-line import/extensions
import * as RAW_PLATFORMS0 from './dpasa.ts';
import * as RAW_PLATFORMS1 from './index.ts';
import * as RAW_PLATFORMS2 from './mipasa.ts';
import * as RAW_PLATFORMS3 from './papillon.ts';
import * as RAW_PLATFORMS4 from './pasa.ts';
import * as RAW_PLATFORMS5 from './unbounded.ts';
let RAW_PLATFORMS = { "dpasa": RAW_PLATFORMS0, "index": RAW_PLATFORMS1, "mipasa": RAW_PLATFORMS2, "papillon": RAW_PLATFORMS3, "pasa": RAW_PLATFORMS4, "unbounded": RAW_PLATFORMS5 };
const PLATFORMS = Object.fromEntries(Object.entries(RAW_PLATFORMS)
    .filter(x => x[0] !== 'index')
    .map(([k, v]) => [k, v.default]));
export default PLATFORMS;
