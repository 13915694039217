import { BaseColor, GlobalStyles, PlatformLoader, Typography, spacing, themeColor, themes } from '@unbounded/unbounded-components';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { LOADED_THEME } from '~persistence/theme';
import { getPlatform } from '~platform/platform';
const LoaderStyle = styled.div `
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const LoaderPleaseWait = styled.div `
  color: ${themeColor(BaseColor.shade_70)};
  margin-top: ${spacing(0.5)};
  ${Typography.titleMedium};
  ${Typography.textSemiStrong};
`;
const Loader = () => (React.createElement(ThemeProvider, { theme: themes[LOADED_THEME] },
    React.createElement(GlobalStyles, null),
    React.createElement(LoaderStyle, null,
        React.createElement(PlatformLoader, { platform: getPlatform().key }),
        React.createElement(LoaderPleaseWait, null, "Initializing..."))));
export default Loader;
