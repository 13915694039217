export const IS_DEVELOPMENT = window.location.origin.includes('localhost');
export function getDefaultEnvironmentConfig() {
    return {
        environmentId: IS_DEVELOPMENT ? LOCAL_ENV : 'production',
    };
}
let ENVIRONMENT_CONFIG = getDefaultEnvironmentConfig();
export function updateEnvironmentConfig(newConfig) {
    if (newConfig.environmentId === 'local') {
        return;
    }
    ENVIRONMENT_CONFIG = { ...ENVIRONMENT_CONFIG, ...newConfig };
}
export const getEnvironmentConfig = () => ENVIRONMENT_CONFIG;
