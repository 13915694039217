// @ts-ignore TypeScript does not natively allow "*.ts" syntax and fails during build.
// eslint-disable-next-line import/extensions
import * as RAW_CONFIGURATIONS0 from './crypto.ts';
import * as RAW_CONFIGURATIONS1 from './dpasa.ts';
import * as RAW_CONFIGURATIONS2 from './index.ts';
import * as RAW_CONFIGURATIONS3 from './laser.ts';
import * as RAW_CONFIGURATIONS4 from './local-crypto.ts';
import * as RAW_CONFIGURATIONS5 from './local-dpasa.ts';
import * as RAW_CONFIGURATIONS6 from './local.ts';
import * as RAW_CONFIGURATIONS7 from './production-ch.ts';
import * as RAW_CONFIGURATIONS8 from './production.ts';
import * as RAW_CONFIGURATIONS9 from './qa.ts';
import * as RAW_CONFIGURATIONS10 from './sage.ts';
import * as RAW_CONFIGURATIONS11 from './staging.ts';
let RAW_CONFIGURATIONS = { "crypto": RAW_CONFIGURATIONS0, "dpasa": RAW_CONFIGURATIONS1, "index": RAW_CONFIGURATIONS2, "laser": RAW_CONFIGURATIONS3, "local-crypto": RAW_CONFIGURATIONS4, "local-dpasa": RAW_CONFIGURATIONS5, "local": RAW_CONFIGURATIONS6, "production-ch": RAW_CONFIGURATIONS7, "production": RAW_CONFIGURATIONS8, "qa": RAW_CONFIGURATIONS9, "sage": RAW_CONFIGURATIONS10, "staging": RAW_CONFIGURATIONS11 };
const CONFIGURATIONS = Object.fromEntries(Object.entries(RAW_CONFIGURATIONS)
    .filter(x => x[0] !== 'index')
    .map(([k, v]) => [k, v.default]));
export default CONFIGURATIONS;
