const CONFIGURATION = {
    defaultTheme: 'mipasa-light',
    authorizedDomains: ['localhost'],
    apps: {
        mipasa: {
            enabled: true,
            url: 'https://localhost:3000',
        },
        unbounded: {
            enabled: true,
            url: 'https://localhost:3000',
        },
        papillon: {
            enabled: true,
            url: 'https://localhost:3000',
        },
        pasa: {
            enabled: false,
        },
        dpasa: {
            enabled: false,
        },
    },
    oidc: {
        github: true,
        google: true,
        ethereum: true,
    },
    sentry: {
        enabled: false,
    },
    token: {
        tokenAddress: '0xa62a7344839Bd748DC4F0351D207B95464e65C7E',
        vestingAddress: '0x36acB157b2760284Bd0D32153F5F2Bc35BbcF68C',
        internalAddress: '0xe2abD1E57c8D6f0D21645a8a0Af6C4251a4395f5',
        name: 'UN',
        decimals: 18,
        unitRatio: 1,
    },
};
export default CONFIGURATION;
