export function setCookie(name, value) {
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; path=/`;
}
export function getCookie(name) {
    const pair = document.cookie.split(';').find(x => x.trim().startsWith(`${encodeURIComponent(name)}=`));
    if (!pair) {
        return undefined;
    }
    const i = pair.indexOf('=');
    return decodeURIComponent(pair.substring(i + 1));
}
export function deleteCookie(name) {
    document.cookie = `${encodeURIComponent(name)}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}
