import { ThemeName, keysOf } from '@unbounded/unbounded-components';
import getApplicationConfig from '~platform';
import { deleteStorage, getStorage, setStorage } from './hack-storage';
const LEGACY_THEME_STORAGE_KEY = 'unbounded_theme';
const THEME_STORAGE_KEY = 'idm_theme';
const THEME_STORAGE_VERSION = 1;
export const SUPPORTED_THEMES = {
    'papillon-dark': ThemeName.papillonDark,
    'papillon-light': ThemeName.papillonLight,
    'mipasa-dark': ThemeName.mipasaDark,
    'mipasa-light': ThemeName.mipasaLight,
    'unbounded-dark': ThemeName.unboundedDark,
    'unbounded-light': ThemeName.unboundedLight,
    'pasa-dark': ThemeName.pasaDark,
    'pasa-light': ThemeName.pasaLight,
    'dpasa-dark': ThemeName.dpasaDark,
    'dpasa-light': ThemeName.dpasaLight,
};
const SUPPORTED_THEMES_BY_NAME = keysOf(SUPPORTED_THEMES).reduce((acc, key) => ({
    ...acc,
    [SUPPORTED_THEMES[key]]: key,
}), {});
const DEFAULT_THEME = getApplicationConfig().defaultTheme;
export function storeTheme(themeName) {
    const theme = SUPPORTED_THEMES_BY_NAME[themeName] || DEFAULT_THEME;
    setStorage(THEME_STORAGE_KEY, JSON.stringify({ theme, version: THEME_STORAGE_VERSION }));
    deleteStorage(LEGACY_THEME_STORAGE_KEY);
}
// fetches redirect URI that was previously stored
export function fetchTheme() {
    const themeRaw = getStorage(THEME_STORAGE_KEY) || '{}';
    let savedThemeData = null;
    try {
        savedThemeData = JSON.parse(themeRaw);
    }
    catch (e) {
        // Do nothing
    }
    return savedThemeData?.version === THEME_STORAGE_VERSION
        ? SUPPORTED_THEMES[savedThemeData?.theme] || SUPPORTED_THEMES[DEFAULT_THEME]
        : SUPPORTED_THEMES[DEFAULT_THEME];
}
export function getThemeFromParams(defaultTheme) {
    const params = new URLSearchParams(window.location.search);
    const param = params.get('theme');
    const themeNameFromParam = SUPPORTED_THEMES[param];
    // Theme was updated, store it
    if (themeNameFromParam) {
        storeTheme(themeNameFromParam);
    }
    const themeName = themeNameFromParam || defaultTheme || SUPPORTED_THEMES[DEFAULT_THEME];
    return { themeName, param };
}
export const LOADED_THEME = getThemeFromParams(fetchTheme()).themeName;
